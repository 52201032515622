@import "../../App.scss";

.loading-comp{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .loading-comp-control{
        margin: auto;
        display: flex;
        flex-direction: column;

        .loading-comp-resume{
            width: 300px;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            object-fit: contain;
            animation: bounce 1s infinite;
        }

        .loading-comp-resume-text{
            margin-left: auto;
            margin-right: auto;
            font-size: 20px;
            color: $text-color;
            font-family: $fontRegular;
        }
    }


    @keyframes bounce {
        0%, 100% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-20px);
        }
      }
}