@import "../../App.scss";

.landing {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #EDF0F5;

    .landing-control {

        display: grid;
        grid-template-columns: 50% 50%;
        padding-top: 80px;
        

        @include media-breakpoint-down(md){
            grid-template-columns: 100%;
        }


        .landing-left-control {
            display: flex;
            flex-direction: column;

            .landing-left-img {
                width: 80%;
                height: auto;
                object-fit: contain;
                @include media-breakpoint-down(md){
                    width: 90%;
                    margin-left: auto;
                    margin-right: auto;
                }
        
            }

            .landing-left-text {
                font-size: 20px;
                color: $text-color;
                font-family: $fontRegular;
                margin-top: 24px;
                width: 80%;
                @include media-breakpoint-down(md){
                    width: 100%;
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .landing-left-button {
                border-radius: 8px;
                background-color: $button-primary-color;
                min-height: 58px;
                max-width: 309px;
                margin-top: 36px;
                @include media-breakpoint-down(md){
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .landing-right-control {
            display: flex;

            .landing-right-img {
                width: 100%;
                height: auto;
                object-fit: contain;
                margin-left: -25%;
                margin-top: -10%;
                @include media-breakpoint-down(md){
                    width: 70%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }



    }

    .landing-section-2-control {
        display: flex;
        margin-top: 40px;
        margin-bottom: 40px;
        flex-direction: column;

        @include media-breakpoint-down(md){
            margin-top: 40%;
        }

        .landing-section-2-img {
            width: 80px;
            height: 80px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;

            
        }

        .landing-section-2-text {
            font-size: 18px;
            color: $text-color;
            font-family: $fontRegular;
            margin-top: 24px;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            @include media-breakpoint-down(md){
                width: 90%;

            }
        }

        .landing-section-2-divider{
            width: 70%;
            height: 1px;
            background-color: #DADADA;
            margin-top: 60px;
            margin-left: auto;
            margin-right: auto;
            @include media-breakpoint-down(md){
               margin-left: auto;
               margin-right: auto;
                
            }
        }
    }

    .landing-section-3-control{
        display: grid;
        grid-template-columns: 30% 70%;
        margin-top: 40px;
        margin-bottom: 80px;

        @include media-breakpoint-down(md){
            grid-template-columns: 100%;
             
         }

        .landing-section-3-img{
            width: 90%;
            height: auto;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
            margin-top: auto;
            margin-bottom: auto;
            @include media-breakpoint-down(md){
                width: 70%;
             }
    
        }

        .landing-section-3-text-control{
            display: flex;
            flex-direction: column;
            margin-left: 60px;

            @include media-breakpoint-down(md){
                margin-left: auto;
                margin-right: auto;
            }

            .landing-section-3-text-title{
                font-size: 44px;
                color: $text-color;
                font-family: $fontMedium;
                @include media-breakpoint-down(md){
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                 }
            }
            .landing-section-3-text-desc{
                font-size: 20px;
                color: $text-color;
                font-family: $fontRegular;
                @include media-breakpoint-down(md){
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                 }
            }

            .landing-section-3-button {
                border-radius: 8px;
                background-color: $button-primary-color;
                min-height: 58px;
                max-width: 309px;
                margin-top: 36px;
                @include media-breakpoint-down(md){
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;
                 }
            }
        }
    }
}