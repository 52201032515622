@import "../../App.scss";

.questions {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .questions-control {

        display: flex;
        flex-direction: column;

        .question-upload-resume-control {
            margin-top: 24px;
            display: flex;
            flex-direction: column;

            .question-upload-resume-img {
                width: 300px;
                height: auto;
                margin-top: -5%;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }

            .question-upload-resume-button {
                border-radius: 8px;
                background-color: $button-primary-color;
                min-height: 58px;
                width: auto;
                padding: 0px 40px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 36px;

                @include media-breakpoint-down(md) {
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;
                }
            }

            .question-upload-resume-create-control {
                display: flex;
                margin-top: 24px;
                margin-left: auto;
                margin-right: auto;

                .question-upload-resume-create-text {
                    font-size: 18px;
                    color: $text-color;
                    font-family: $fontRegular;

                    @include media-breakpoint-down(md){
                        text-align: center;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                .question-upload-resume-create-img {
                    cursor: pointer;
                    width: auto;
                    height: 40px;
                    object-fit: contain;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-left: 4px;
                }

            }
        }

        .fade-out {
            opacity: 1;
            animation: fadeOut 0.5s ease-in-out forwards;
        }
        .fade-in {
            opacity: 1;
            animation: fadeIn 0.5s ease-in forwards;
        }
        .questions-text-control {
            margin-top: 10%;
            display: flex;
            flex-direction: column;
            max-width: 762px;
            border-radius: 8px;
            background-color: #fff;
            width: 100%;
            padding: 24px;
            margin-left: auto;
            margin-right: auto;

            .questions-text-category-control{
                display: block;
                margin-bottom: 24px;
            }

            .questions-text-category{
                border: 2px solid;
                width: auto;
                padding: 4px 16px;
                border-radius: 4px;
                font-size: 13px;
                font-family: $fontRegular;
              
            }
            

            .questions-text-title {
                font-size: 24px;
                font-family: $fontRegular;
                color: $text-color;
                text-align: left;
                margin-bottom: 24px;
            }

            .questions-text-choice-control {
                width: 100%;
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-left: auto;
                margin-right: auto;


                .questions-text-choice-item {
                    width: 100%;
                    min-height: 48px;
                    cursor: pointer;
                    margin-bottom: 24px;
                    background-color: #f7f8fa;
                    border-radius: 8px;
                    display: flex;
                    padding: 4px 16px;

                    &:hover {
                        box-shadow: 0px 0px 5px $primary-color;
                    }

                    &:active {
                        background-color: $primary-color;
                    }

                    &:active .questions-text-choicetext {
                        color: white;
                    }

                    .questions-text-title {
                        font-size: 16px;
                        margin-top: auto;
                        text-align: center;
                        margin-bottom: auto;
                        font-family: $fontRegular;
                        color: $text-color;
                    }

                    .questions-text-choicetext {
                        font-size: 14px;
                        margin-top: auto;
                        text-align: left;
                        margin-bottom: auto;
                        font-family: $fontRegular;
                        color: $text-color;

                    }

                    .questions-arrow-img {
                        width: 9px;
                        height: 24px;
                        margin-left: auto;
                        margin-top: auto;
                        margin-bottom: auto;
                        object-fit: contain;
                    }

                }
            }
        }

    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            display: none;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
            display: flex;
        }
    }
}