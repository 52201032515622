@import "../../App.scss";

.result {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .result-control {
        margin-top: 5%;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md){
            margin-top: 10%;
        }

        .result-heart-control {
            position: relative;
            display: flex;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            .result-h1-img {
                position: absolute;
                left: 30%;
                top: 70%;
                width: 50px;
                height: 50px;
                object-fit: contain;

                @include media-breakpoint-down(lg) {
                    left: 10%;
                }
            }

            .result-h2-img {
                position: absolute;
                left: 35%;
                top: 0;
                width: 50px;
                height: 50px;
                object-fit: contain;
                @include media-breakpoint-down(lg) {
                    left: 20%;
                }
            }

            .result-h3-img {
                position: absolute;
                right: 30%;
                top: 70%;
                width: 50px;
                height: 50px;
                object-fit: contain;
                @include media-breakpoint-down(lg) {
                    right: 10%;
                }
            }

            .result-h4-img {
                position: absolute;
                right: 35%;
                top: 0;
                width: 50px;
                height: 50px;
                object-fit: contain;
                @include media-breakpoint-down(lg) {
                    right: 20%;
                }
            }

            .result-heart-center {
                width: 60px;
                height: 60px;
                margin-top: -2%;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .result-text-control {
            margin-bottom: 24px;
            display: flex;
            flex-direction: column;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
            justify-content: center;
            @include media-breakpoint-down(lg) {
                width: 100%;
                margin-top: 36px;
            }

            .result-text-title {
                width: 90%;
                font-size: 24px;
                font-family: $fontMedium;
                color: $text-color;
                text-align: center;
                margin-top: 24px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 14px;
            }

            .result-img-dating{
                width: 300px;
                height: 300px;
                object-fit: cover;
                border-radius: 50%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 24px;
            }

            .result-gooogle-map{
                margin-left: auto;
                margin-right: auto;
            }

            .result-suggest-header{
                font-size: 15px;
                font-family: $fontRegular;
                color: $text-color;
                margin-left: auto;
                text-decoration: underline;
                margin-top: 36px;
                margin-right: auto;
            }
            .result-suggest-desc{
                font-size: 32px;
                font-family: $fontMedium;
                color: $text-color;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                margin-bottom: 24px;

                @include media-breakpoint-down(md){
                    font-size: 22px;
                    
                }
            }

            .result-text-desc {
                font-size: 18px;
                font-family: $fontRegular;
                color: $text-gray-color;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 16px;
            }
        }
    }

    .result-divider-control {
        width: 80%;
        display: flex;
        margin-top: 90px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;

        .result-divider-img {
            width: 22px;
            height: auto;
            object-fit: contain;
            margin-left: 2px;
            margin-right: 2px;

            @include media-breakpoint-down(md) {
                width: 13px;
            }
        }
    }

    .result-match-control {
        margin-top: 90px;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 100px;

        .result-match-h1-img {
            position: absolute;
            bottom: 5%;
            width: 140px;
            height: 140px;
            object-fit: contain;

            @include media-breakpoint-down(lg) {
                width: 80px;
                height: 80px;
            }

            @include media-breakpoint-down(md) {
                width: 50px;
                height: 50px;
            }
        }

        .result-match-h2-img {
            position: absolute;
            top: 0;
            left: 10%;
            width: 140px;
            height: 140px;
            object-fit: contain;

            
            @include media-breakpoint-down(lg) {
                left: 5%;
                width: 80px;
                height: 80px;
            }

            @include media-breakpoint-down(md) {
                width: 50px;
                height: 50px;
            }
        }

        .result-match-h3-img {
            position: absolute;
            right: 10%;
            top: 0%;
            width: 140px;
            height: 140px;
            object-fit: contain;

            @include media-breakpoint-down(lg) {
                right: 5%;
                width: 80px;
                height: 80px;
            }

            @include media-breakpoint-down(md) {
                width: 50px;
                height: 50px;
            }
        }

        .result-match-h4-img {
            position: absolute;
            right: 0;
            bottom: 20%;
            width: 80px;
            height: 80px;
            object-fit: contain;

            @include media-breakpoint-down(lg) {
                bottom: 50%;
                width: 40px;
                height: 40px;
            }

            @include media-breakpoint-down(md) {
                width: 40px;
                height: 40px;
            }
        }

        .result-match-h5-img {
            position: absolute;
            right: 40%;
            bottom: 0%;
            width: 190px;
            height: 190px;
            object-fit: contain;

            @include media-breakpoint-down(lg) {
                width: 120px;
                height: 120px;
            }

            @include media-breakpoint-down(md) {
                width: 60px;
                height: 60px;
            }
        }

        .result-match-title {
            font-size: 24px;
            font-family: $fontMedium;
            color: $text-color;
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;
        }

        .result-match-desc {
            font-size: 18px;
            font-family: $fontRegular;
            color: $text-color;
            margin-left: auto;
            margin-right: auto;
            width: 50%;
            text-align: center;

            @include media-breakpoint-down(md){
                width: 90%;
            }
        }

        .result-match-button {
            border-radius: 8px;
            background-color: $button-primary-color;
            min-height: 50px;
            padding: 0px 48px;
            margin-left: auto;
            z-index: 1;
            margin-right: auto;
            margin-top: 36px;

            @include media-breakpoint-down(md) {
                margin-left: auto;
                margin-right: auto;
                width: auto;
            }
        }

        .result-match-blur-img {
            width: 100%;
            height: auto;
            object-fit: contain;
            margin-top: -5%;

            @include media-breakpoint-down(md) {
                margin-top: 40px;
            }
        }
    }

    .result-gq-control{
        margin-top: 30px;
        display: grid;
        grid-template-columns: 50% 50%;
        max-width: 766px;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(md){
            width: auto;
            max-width: auto;
            grid-template-columns: 50% 50%;
        }

        .result-gq-img{
            cursor: pointer;
            width: auto;
            height: 300px;
            object-fit: contain;

            @include media-breakpoint-down(md){
                height: 150px;
            }
        }
    }

    .result-gq-button{
        background-color: #ef4123;
        border: none;
        border-radius: 8px;
        min-width: 300px;
        height: 46px;
        color: white;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        font-family: $fontMedium;
        font-size: 16px;

        @include media-breakpoint-down(md){
            min-width: 200px;
            font-size: 15px;
            height: 38px;
        }

        &:hover{
            background-color: rgb(194, 39, 28);
        }
    }

  
}
.result-gg-map-iframe{
    width: 450px;
    height: 300px;

    @include media-breakpoint-down(md){
        width: 300px;
        height: 200px;
    }
}