@import "../App.scss";

.email-submit-modal{

    .email-submit-text-title{
        font-family: $fontRegular;
        font-size: 13px;
        color: $text-color;
        margin-bottom: 16px;
    }
    .email-submit-text-header{
        font-family: $fontMedium;
        font-size: 15px;
        color: $text-color;
    }


    .email-submit-button{
        border-radius: 8px;
        background-color: $button-primary-color;
        min-height: 38px;
        width: auto;
        padding: 0px 40px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 36px;
    }

}